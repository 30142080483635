.noseHelp{
     background-color: #f5f5f5; 
    /* background-color: rgb(241, 245, 249)!important; */
}

.fontHelpFaq{
     background-color: #f5f5f5; 
   /* background-color: rgb(241, 245, 249)!important; */
}



@media screen and  (min-width: 600px){
    .padHelp{
     padding-left: 2rem!important;
     padding-right: 2rem!important;
    }
  .boxHelp{
    padding-bottom: 11.7rem!important;
    padding-top: 4rem!important;
  }

  .pregCent{
    font-size: 1.9rem!important;
  }
  .searchHelpCen{
    font-size: 1rem!important;
  }
  .searchHelpCenter2{
    max-width: 24rem!important;
    margin-top: 4rem!important;
  }

  .searchHelpCenter3{
    max-width: 30rem!important;
    margin: auto!important;
    margin-top: 32px!important;

  }
  .noseHelp{
    padding-left: 2rem!important;
    padding-right: 2rem!important;
    
  }
  .hartoMediaHelp{
    margin-top: -6.0rem!important;
  }
  .fontHelpFaq{
    font-size: 1.8rem!important;
  }
    
}

@media screen and (min-width: 960px) {
    .hartoMediaHelp{
        -webkit-column-gap: 2.0rem!important;
        column-gap: 2.0rem!important;
        row-gap: 0!important;
        grid-template-columns: repeat(3,minmax(0,1fr))!important;
        max-width: 60rem!important;
    }
    .parrafoHelpCard{
        max-width: 16rem!important;
    }
    .pregCent{
        font-size: 2.4rem!important;
      }
    
}



.otroSvgSearch{
    opacity: 0.25 !important;
    --tw-text-opacity: 1!important;
    color: rgb(97 97 97/var(--tw-text-opacity))!important;
}

.transicionHelp{
    text-decoration: none!important;
     box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow)!important;
     box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)!important;
     transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
     transition-duration: .15s!important;
    transition-property: box-shadow!important;
    transition-timing-function: cubic-bezier(.4,0,.2,1)!important;
  
    transition-duration: .15s!important;
   
    --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px 0 var(--tw-shadow-color)!important;

}

.transicionHelp:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)!important;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),0 4px 6px -2px var(--tw-shadow-color)!important;
    box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow)!important;
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)!important;
    }

    .searchHelpCenter2:hover > .MuiOutlinedInput-notchedOutline{
        border-color: rgb(255,255,255)!important;
        border-width:2px!important;
    }
    .searchHelpCenter2 > .MuiOutlinedInput-notchedOutline{
        border-color: rgb(255,255,255,0.23)!important;
       
    }

    .searchHelpCenter3:hover > .MuiOutlinedInput-notchedOutline{
        border-color: #004e56!important;
        border-width:2px!important;
    }
    .searchHelpCenter3 > .MuiOutlinedInput-notchedOutline{
        border-color: gray!important;
       
    }

    .Mui-focused.searchHelpCenter3 > .MuiOutlinedInput-notchedOutline{
        border-color: #004e56!important;
        border-width:2px!important;
    }
.Mui-focused.searchHelpCenter2 > .MuiOutlinedInput-notchedOutline{
    border-color: rgb(255,255,255)!important;
    border-width:2px!important;
}

.helpCentContact{
   background-color: #F5F5F5!important; 
 /*  background-color: rgb(241, 245, 249)!important;  */
}
.helpCentContact2{
     background-color: #f5f5f5!important; 
    /* background-color: #fff!important; */
 }


 .PaperContactHelp2{
    padding-bottom: 32px!important;
 }

@media screen and  (min-width: 600px){
.helpCentContact{
    padding: 40px !important;
    padding-top: 0px!important;
}
.helpCentContact2{
    padding: 0px !important;
}
.gridConHelp{
    margin-top: 0px!important;
}
.fonteoSoporte{
    font-size: 3rem!important;
}
.PaperContactHelp{
    padding-bottom: 28px!important;
    padding: 40px!important;
    margin-top: 32px!important;
}
.PaperContactHelp2{
    padding-bottom: 0px!important;
    padding: 40px!important;
    padding-top: 0px!important;
    margin-top: 0px!important;
}
.padformConHelp{
    padding-left: 24px!important;
    padding-right: 24px!important;
}

.padformConHelp2{
    padding-left: 0px!important;
    padding-right:0px!important;
}


.botoneConta{
    margin-right: 24px!important;
}

}



.boton-subircv2-desab{
    color: rgba(0, 0, 0, 0.26)!important;
    background: rgba(0, 0, 0, 0.12)!important;
    cursor: pointer;
    
      text-align: center!important;
      box-sizing: border-box!important;
      font-weight: 500!important;
      border-radius: 0!important;
      border:0px solid rgba(0, 0, 0, 0.26)!important
  }

  
  .boton-subircv2-desab:hover{
    color: rgba(0, 0, 0, 0.26);
      background: rgba(0, 0, 0, 0.12)!important;
  }




  @media screen and  (min-width: 600px){
.margeneoFaq{
    margin-top: 32px!important;
}
.margeneoFaqInicio{
    margin-top: 12px!important;
}
  }

 

.widthfull{
    width: 100%!important;
}

.margineoFaqList{
    margin-top: 32px!important;
}

@media screen and  (min-width: 600px){
.searchAyuda{
    padding: 32px!important;
    padding-left: 4vw!important;
    padding-right: 4vw!important;
}

.gridSearchde{
    padding: 32px !important;
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    padding-top: 0px!important;
}
}

.shadoweo2{
    box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow)!important;
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)!important;
    --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px 0 var(--tw-shadow-color)!important;
}

.marginmotionDivSearch{
    margin-bottom: 28px!important;
}

.backColorSearch{
    background-color: rgb(241, 245, 249)!important;
}

.linkHeight{
    height: 24px !important;
}

.coloreBack{
    background-color: rgb(241, 245, 249)!important;
}

.linkeoSearchsinSub{
    text-decoration: none!important;
}

.linksinDecor2{
    text-decoration: none!important;
    color:black!important;
    font-weight: 600!important;
}

@media screen and (min-width: 600px) {
    .textoPricingConta{
        font-size: 48px!important;
    }

    .paddingFinalPricing2{
        padding-top: 48px!important;
        padding-bottom: 0px!important;
        padding-left: 64px!important;
        padding-right: 64px!important;
    }

}

.boton-subircv2ConTacto{
    color: rgba(0, 0, 0, 0.87)!important;
    background: #f9b934!important;
    cursor: pointer;
    
      text-align: center!important;
      box-sizing: border-box!important;
      font-weight: 500!important;
      border-radius: 5px!important;
  }

  .boton-subircv2ConTacto:hover{
    color: rgba(0, 0, 0, 0.87);
      background: #FF9800!important;
  }

  .modAyudaSnap{
    margin-top: 1.2rem!important;
    text-align: center!important;
    letter-spacing: -0.025em!important;
    line-height: 1.5!important;
    font-size: 1rem!important;

  }

  @media screen and (min-width: 600.01px) {
.modAyudaSnap{
    font-size: 20px!important;
}
  }

  .colorAtrasAyuda{
    background-color: rgb(15, 23, 42)!important;
  }

  .contactoModSnap{
    margin-top: 4px!important;
    font-size: 32px!important;
    font-weight: 800!important;
    letter-spacing: -0.025em!important;
    line-height: 1.25!important;
    text-align: center!important;
  }

  @media screen and (min-width: 600px) {
    .contactoModSnap{
        font-size: 48px!important;
    }
}

.textoPlanes2Ayuda{
    margin-top: 12px!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    text-align: center!important;
    letter-spacing: -0.025em!important;
    font-size: 16px!important;
}


@media screen and (min-width: 600px) {
    .textoPlanes2Ayuda{
      font-size: 20px!important;
  }
}