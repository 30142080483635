@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");



body{
  background-size: 100% 100%;
  /* background-size: cover; */
  background-color: #edeae5;
}


.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinnerContainer4 {
  /* position: fixed;
  top: calc(50% - 0px);
 
  left: calc(50% - 35px);
   background-color: rgba(0, 0, 0, 0.2); 
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height:calc(100% - 100px) */
}

.loadingSpinnerContainer5 {
  /* position: fixed;
  top: calc(50% - 0px);
 
  left: calc(50% - 35px);
   background-color: rgba(0, 0, 0, 0.2); 
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
 background-color: rgba(0, 0, 0, 0.1); 
  z-index:3;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height:calc(100% - 100px) */
}


.loaderSpin{
  background-color: rgba(0, 0, 0, 0.2);
  height: calc(100vh - 60px);
}

.loadingSpinnerContainer2 {
  /* position: absolute; */
 /*  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinnerContainer3 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
 /*  background-color: rgba(0, 0, 0, 0.2); */
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
 
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
 

/* @media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
} 
 */

 a:hover{
  cursor: pointer;
 }
 .uno {

  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  min-height: 0;
  position: relative;
  background-color: #edeae5;
  min-height: calc(100vh - 80px);
  flex-direction: column;
  justify-content: space-between;
 }
 .uno2 {

  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  /* align-items: center; */
  min-height: 0;
  position: relative;
  background-color: #edeae5;
  min-height: calc(100vh - 60px);
  flex-direction: column;
  justify-content: space-between;
 }


 .dos {
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.6rem;
  padding-bottom: 3.2rem;
  width: 100%;
  min-height: 100%!important;
  border-radius: 0!important;
  margin:auto;
 
/*    margin-top:2rem;
  margin-bottom:2rem;  */
 }

 .tres{
  width: 100%;
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
 }
 
.cuatro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: cuatro;
}

.cinco {
  font-size: 2.0rem !important;
  line-height: 1.25 !important;
  font-weight: 800 !important;
  letter-spacing: -0.025em !important;
  margin-top: 0rem !important;
}

.cinco2{
  font-size: 1.9rem !important;
  line-height: 1.25 !important;
  font-weight: 800 !important;
  letter-spacing: -0.025em !important;
  margin-top: 0rem !important;
}


.chinque {

    align-items: baseline;
    margin-top: 0.4rem;
    display: flex;

}

.chinque2 {

  align-items: baseline;
  margin-top:0.4px;
  display: flex;

}


.ver{
  color: #004e56!important;
  text-decoration-color: #004e56!important;
  font-size: 0.8rem!important;
}

.ver2{
  color: #004e56!important;
  text-decoration-color: #004e56!important;
  font-size: 14px!important;
}
.ver22{
  color: #004e56!important;
  text-decoration-color: #004e56!important;
  
}

.seis {
font-size: 1rem!important;
  margin: 0px!important;
  font-weight: 400!important;
 line-height: 1.5!important;
 color: rgb(17, 24, 39)!important;
}

.siete{
  margin-left: 0.4rem !important;
  font-weight: 500!important;
  font-size: 1rem;
  color: #004e56!important;
  text-decoration-color: #004e56!important;
}

.ocho{
  display: flex !important;
  margin-top: 1.4rem !important;
  flex-direction: column !important;
  justify-content: center!important;
  width: 100% !important;
}

.ocho2{
  display: flex !important;
  margin-top: 24px !important;
  flex-direction: column !important;
  justify-content: center!important;
  width: 100% !important;
}

.octo{
  justify-content: space-between;
  align-items: center ;
  flex-direction: row ;
  display: flex ;
  font-size: 0.8rem!important;
}

.neff{
  font-size: 0.8rem !important;
  color: rgb(17, 24, 39);
  /* transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}

.nueve{
margin-bottom: 1.0rem !important;
}

.nove{
  width: 100%;
  margin-top: 1rem!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
    border: 0px;
    margin: 0px;
font-weight: 600!important;
    font-size: 1.5rem;
    line-height: 1.75;
    min-width: 64px!important;
    padding: 8px 22px;
    border-radius: 28px!important;
    color: rgba(0, 0, 0, 0.87)!important;
    background-color: #f9b934!important;
    box-shadow: none;
}

.diez{
  font-weight: 500;
}

.once{
  width: 100%;
  margin-top: 4rem;
}

.doce {
  flex: 1 1 auto;
  margin-top: 8rem;
  align-items: center;
}

.trece{
  margin-top: 1px;
  flex: 1 1 auto;
  border-top-width: 1px;
}

.catorce{
  margin-left: 2rem;
  margin-right: 2rem;
}

.quince{
  display: flex;
  margin-top: 8rem;
  margin-left: 4rem;
  align-items: center;
}

.dieciseis{
  flex: 1 1 auto;
}




@media (min-width: 600px) {
  /* .uno {
    justify-content: center;
  } */

  .dos {
    padding: 3.2rem;
    
    width: auto;
    border-radius: 1.6rem !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    min-height: auto;
  }
  .tres {
    margin-left: 0;
margin-right: 0; 
width: 32rem;
  }
  .cuatro {
    flex-direction: row; 
    justify-content: space-between; 
  }
  .octo{
    justify-content: space-between;
    flex-direction: row;
  }
}

.nove:hover{
    background-color: #FF9800!important;
   
}

.otros-inic{
  align-items: center;
  display: flex;
  margin-top: 1.6rem;
}

.raya{
  border-top-width: 1px!important;
  flex: 1 1 auto!important;
  margin-top: 1px;
  border-color: rgb(226, 232, 240);
  box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    
}

.palabras{
  margin-left: 0.8rem!important;
    margin-right: 0.8rem!important;
    color: rgb(107, 114, 128);
}

.botonsocial{
  background-color: rgba(0,0,0,0)!important;
border: 0!important;
box-sizing: border-box!important;
cursor: pointer!important;

    font-weight: 600!important;
line-height: 40px!important;
    overflow: hidden!important;
    outline-width: 2px!important;
    padding: 0 24px!important;
    position: relative!important;
    text-align: center!important;
    text-decoration: none!important;
transition-duration: 167ms!important;
transition-property: background-color,box-shadow,color,-webkit-box-shadow!important;
transition-timing-function: cubic-bezier(0, 0, 0.2, 1)!important;
    vertical-align: middle!important;
box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%), inset 0 0 0 2px rgb(0 0 0 / 0%), inset 0 0 0 1px rgb(0 0 0 / 0%)!important;
    color: rgba(0,0,0,0.6) !important;
    border-radius: 28px !important;
display: flex!important;
    width: 100%!important;
    height: 42px;
align-items: center!important;
justify-content: center!important;
    margin-top: 1.6rem!important;

}

.botonsocial:hover{
  background-color: rgba(207,207,207,0.25)!important;
/* box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important; */
}

.botonsocial3{
  background-color: #fff!important;
opacity:0.8;
border: 0!important;
cursor: pointer!important;
    font-weight: 600!important;
line-height: 40px!important;
    overflow: hidden!important;
    text-align: center!important;
    vertical-align: middle!important;
    color: rgba(0, 0, 0, 0.87)!important;
    border-radius: 28px !important;
display: flex!important;
    
    height: 42px;
align-items: center!important;
justify-content: center!important;
margin:0px!important;
        width:150px!important;
      
        top:16px!important
}

.botonsocial3A{

    border-radius: 28px !important;
       
        top:16px!important
}

.botonsocial32{
  background-color: #fff!important;
opacity:0.8;
border: 0!important;
cursor: pointer!important;
    font-weight: 600!important;
line-height: 40px!important;
    overflow: hidden!important;
    text-align: center!important;
    vertical-align: middle!important;
    color: rgba(0, 0, 0, 0.87)!important;
    border-radius: 28px !important;
display: flex!important;
    
    height: 42px;
align-items: center!important;
justify-content: center!important;
margin:0px!important;
        width:150px!important;
      
        top:16px!important
}

.botonsocial4{
  padding:9px!important;
  left: 16px !important;
  top: 16px !important;
}

.botonsocial42{
  right: 16px !important;
  top: 16px !important;
}

.botonsocial5{
  right: 8px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial52{
  right: 56px !important;

}

.botonsocial6{
  right: 48px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial62{
  right: 96px !important;
 
}

.botonsocial7{
  right: 8px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial7Sin{
  right: 8px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial8{
  right: 48px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial8Sin{
  right: 48px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial9{
  padding:4px!important;
  left: 8px !important;
  top: 8px !important;
}

.botonsocial9Sin{
  padding:4px!important;
  left: 8px !important;
  top: 8px !important;
}

.botonsocial10{
  right: 88px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial10Sin{
  right: 88px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial11{
  left: calc(1.2rem + 6.4rem + 1.6rem + 14px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}

.botonsocial11Sin{
  left: calc(1.2rem + 6.4rem + 1.6rem + 14px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  /* color:rgba(0, 0, 0, 0.87)!important; */
  line-height: 0.5!important;
  /* opacity: 0.7 !important; */
}

.busquedaLeftAt{
  left: calc(1.2rem + 6.4rem + 1.6rem + 14px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}

.busquedaLeftAt3{
  left: calc(1.2rem + 6.4rem + 1.6rem + 14px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
   color:rgba(0, 0, 0, 0.87)!important; 
  line-height: 0.5!important;
  /* opacity: 0.7 !important; */
}

.busquedaRightAt3{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 162.04px + 4px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  /* opacity: 0.7 !important; */
}
.botonsocial11:hover{
  background-color: #fff!important;
  cursor:default!important;
}

@media (max-width:600px){
  .botonsocial11{
    left: calc(1.2rem + 6.4rem + 0rem + 14px) !important;
  }
  .botonsocial11Sin{
    left: calc(1.2rem + 6.4rem + 0rem + 14px) !important;
  }
}

.busquedaLeftAt:hover{
  background-color: #fff!important;
  cursor:default!important;
}

.botonsocial12{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 125.22px + 4px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}

.botonsocial12Sin{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 125.22px + 4px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  /* color:rgba(0, 0, 0, 0.87)!important; */
  line-height: 0.5!important;
  /* opacity: 0.7 !important; */
}

.busquedaRightAt{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 162.04px + 4px) !important;
  top: calc(6rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}



.botonsocial12:hover{
  background-color: #fff!important;
  cursor:default!important;
}

@media (max-width:600px){
  .botonsocial12{
    left: calc(0rem + 6.4rem + 0rem + 0px + 162.04px + 4px) !important;
  }
  .botonsocial12Sin{
    left: calc(0rem + 6.4rem + 0rem + 0px + 162.04px + 4px) !important;
  }
}

.busquedaRightAt:hover{
  background-color: #fff!important;
  cursor:default!important;
}

.botonsocial13{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px) !important;
  top: calc(12rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}

.botonsocial132{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px) !important;
  top: calc(12rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  /* opacity: 0.7 !important; */
}


.botonsocial13:hover{
  background-color: #fff!important;
  cursor:default!important;
}

.botonsocial14{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 125.22px + 8px) !important;
  top: calc(12rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}


.botonsocial142{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 125.22px + 8px) !important;
  top: calc(12rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  /* opacity: 0.7 !important; */
}

.cambioRight{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 162.04px + 8px) !important;
  top: calc(12rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}

.cambioRight2{
  left: calc(1.2rem + 6.4rem + 1.6rem + 16px + 0px + 0px) !important;
  top: calc(12rem - 23px) !important;
  padding-left:4px!important;
  padding-right:5px!important;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  border-radius: 22px!important;
  color:rgba(0, 0, 0, 0.87)!important;
  line-height: 0.5!important;
  opacity: 0.7 !important;
}



.botonsocial14:hover{
  background-color: #fff!important;
  cursor:default!important;
}

.cambioRight:hover{
  background-color: #fff!important;
  cursor:default!important;
}

.cambioRight2:hover{
  background-color: #fff!important;
  cursor:default!important;
}

 @media (max-width: 991.9px) {
.botonsocial13{
  left:8px!important;
  top:8.5rem!important;
 
}
.botonsocial132{
  left:8px!important;
  top:8.5rem!important;
 
}

.botonsocial14{
  left: 140px !important;
  
  top:8.5rem!important;
 
}

.botonsocial142{
  left: 140px !important;
  
  top:8.5rem!important;
 
}

.cambioRight{
  left: 170px !important;
  
  top:8.5rem!important;
 
}

.cambioRight2{
  left: 8px !important;
  
  top:8.5rem!important;
 
}

}

@media (max-width: 770px) {

  .botonsocial13{
    left:8px!important;
    top:8.5rem!important;
   
  }
  .botonsocial132{
    left:8px!important;
    top:8.5rem!important;
   
  }
.botonsocial14{
  left: auto !important;
  right:8px!important;
  top:8.5rem!important;
 
}

.botonsocial142{
  left: auto !important;
  right:8px!important;
  top:8.5rem!important;
 
}

.cambioRight{
  left: auto !important;
  right:8px!important;
  top:8.5rem!important;
 
}

.cambioRight2{
  left: auto !important;
  right:8px!important;
  top:8.5rem!important;
 
}

}
 
@media (min-width:991.9px){
  .botonsocial5Due{
    display: none !important;
  }
  .botonsocial5{
    display: none !important;
  }
  .botonsocial6{
    display: none !important;
  }
}

@media (max-width: 991.9px) {
.botonsocial3{
  top:8px!important;
  display:none!important;
}

.botonsocial3A{
  top:8px!important;
  display:none!important;
}


.botonsocial4{
  left: 8px !important;
  top: 8px !important;
  padding:4px!important;
}

.botonsocial42{
  display: none !important;
  top: 8px !important;
  padding:4px!important;
}

}

.botonsocial3:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}



.botonsocial4:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}
.botonsocial42:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}

.botonsocial5:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}

.botonsocial5Due:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}

.botonsocial6:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}
.botonsocial7:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}

.botonsocial8:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}


.botonsocial9:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}

.botonsocial10:hover{
  background-color: #FF9800!important;
 /* border:4px solid #FF9800!important; */
}



.botonsocial2{
  background-color: rgba(0,0,0,0)!important;
border: 0!important;
box-sizing: border-box!important;
cursor: pointer!important;
font-weight: 600!important;
    
line-height: 40px!important;
    overflow: hidden!important;
    outline-width: 2px!important;
    padding: 0 24px!important;
    position: relative!important;
    text-align: center!important;
    text-decoration: none!important;
transition-duration: 167ms!important;
transition-property: background-color,box-shadow,color,-webkit-box-shadow!important;
transition-timing-function: cubic-bezier(0, 0, 0.2, 1)!important;
    vertical-align: middle!important;
box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%), inset 0 0 0 2px rgb(0 0 0 / 0%), inset 0 0 0 1px rgb(0 0 0 / 0%)!important;
    color: rgba(0,0,0,0.6) !important;
    border-radius: 28px !important;
display: flex!important;
    width: 100%!important;
    height: 42px;
align-items: center!important;
justify-content: center!important;
    margin-top: 0.8rem!important;
}
.botonsocial2:hover{
  background-color: rgba(207,207,207,0.25)!important;
/* box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important; */
}

.botonsocial22{
  background-color: rgba(0,0,0,0)!important;
border: 0!important;
box-sizing: border-box!important;
cursor: pointer!important;
font-weight: 600!important;
    
line-height: 40px!important;
    overflow: hidden!important;
    outline-width: 2px!important;
    padding: 0 24px!important;
    position: relative!important;
    text-align: center!important;
    text-decoration: none!important;
transition-duration: 167ms!important;
transition-property: background-color,box-shadow,color,-webkit-box-shadow!important;
transition-timing-function: cubic-bezier(0, 0, 0.2, 1)!important;
    vertical-align: middle!important;
box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%), inset 0 0 0 2px rgb(0 0 0 / 0%), inset 0 0 0 1px rgb(0 0 0 / 0%)!important;
    color: rgba(0,0,0,0.6) !important;
    border-radius: 28px !important;
display: flex!important;
    height: 42px;
align-items: center!important;
justify-content: center!important;
    margin-top: 0rem!important;
}
.botonsocial22:hover{
  background-color: rgba(207,207,207,0.25)!important;
 box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important; 
}

.botonsocial222{
  background-color: rgba(0,0,0,0)!important;
border: 0!important;
box-sizing: border-box!important;
cursor: pointer!important;
font-weight: 600!important;
    
/* line-height: 40px!important; */
    overflow: hidden!important;
    outline-width: 2px!important;
    padding: 0 16px!important;
    position: relative!important;
    text-align: center!important;
    text-decoration: none!important;
transition-duration: 167ms!important;
transition-property: background-color,box-shadow,color,-webkit-box-shadow!important;
transition-timing-function: cubic-bezier(0, 0, 0.2, 1)!important;
    vertical-align: middle!important;
box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%), inset 0 0 0 2px rgb(0 0 0 / 0%), inset 0 0 0 1px rgb(0 0 0 / 0%)!important;
    color: rgba(0,0,0,0.6) !important;
    border-radius: 28px !important;
display: flex!important;
    height: 42px;
align-items: center!important;
justify-content: center!important;
    margin-top: 0rem!important;
}
.botonsocial222:hover{
  background-color: rgba(207,207,207,0.25)!important;
 box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important; 
}



.botonsocial223{
  background-color: rgba(0,0,0,0)!important;
  border: 0!important;
  /* box-sizing: border-box!important; */
  cursor: pointer!important;
  font-weight: 600!important;
      
  /* line-height: 40px!important; */
     /*  overflow: hidden!important; */
      outline-width: 2px!important;
/*       padding: 0 16px!important;
 */      position: relative!important;
      text-align: center!important;
      text-decoration: none!important;
  transition-duration: 167ms!important;
  transition-property: background-color,box-shadow,color,-webkit-box-shadow!important;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)!important;
      vertical-align: middle!important;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%), inset 0 0 0 2px rgb(0 0 0 / 0%), inset 0 0 0 1px rgb(0 0 0 / 0%)!important;
      color: rgba(0,0,0,0.6) !important;
      border-radius: 28px !important;
  display: flex!important;
      /* height: 42px; */
  align-items: center!important;
  justify-content: center!important;
      margin-top: 0rem!important;
}
.botonsocial223:hover{
  background-color: rgba(207,207,207,0.25)!important;
  box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important; 
}


.botonsocial224{
  background-color: rgba(0,0,0,0)!important;
border: 0!important;
box-sizing: border-box!important;
cursor: pointer!important;
font-weight: 500!important;
    font-size: 0.8rem!important;
    overflow: hidden!important;
    outline-width: 2px!important;
    padding: 0 24px!important;
    position: relative!important;
    text-align: center!important;
    text-decoration: none!important;
transition-duration: 167ms!important;
transition-property: background-color,box-shadow,color,-webkit-box-shadow!important;
transition-timing-function: cubic-bezier(0, 0, 0.2, 1)!important;
    vertical-align: middle!important;
box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%), inset 0 0 0 2px rgb(0 0 0 / 0%), inset 0 0 0 1px rgb(0 0 0 / 0%)!important;
  
    border-radius: 28px !important;
display: flex!important;
    height: 42px;
align-items: center!important;
justify-content: center!important;

}
.botonsocial224:hover{
  background-color: rgba(207,207,207,0.25)!important;
 /* box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important;  */
}

.botonsocial225{
  background-color: transparent!important;
border: 0!important;
/* box-sizing: border-box!important; */
cursor: pointer!important;
font-weight: 600!important;
    
/* line-height: 40px!important; */
    overflow: hidden!important;
    outline-width: 2px!important;
    padding: 0 16px!important;
    position: relative!important;
    text-align: center!important;
    text-decoration: none!important;
transition-duration: 167ms!important;
transition-property: background-color,box-shadow,color,-webkit-box-shadow!important;
transition-timing-function: cubic-bezier(0, 0, 0.2, 1)!important;
    vertical-align: middle!important;
 box-shadow:none!important;
     color: rgba(0,0,0,0.6) !important;
    /* border-radius: 28px !important; */
display: flex!important;
    height: 42px;
align-items: center!important;
justify-content: center!important;
    margin-top: 0rem!important;
}
.botonsocial225:hover{
  background-color: rgba(207,207,207,0.5)!important;
 /* box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important;  */
}


.letras-boton{
  margin-left: 8px;
}

.terminos-reg{
  font-size: 0.8rem !important;
  color: #d32f2f!important;
}

.Mui-disabled{
  color: rgba(0, 0, 0, 0.26)!important;
    box-shadow: none!important;
    background-color: rgba(0, 0, 0, 0.12)!important;
    pointer-events: inherit!important; 
    cursor: not-allowed!important;

    
}

.botonsocial2.Mui-disabled:hover{
  background-color:rgba(0, 0, 0, 0.12)!important;
}

.botonsocial.Mui-disabled:hover{
  background-color:rgba(0, 0, 0, 0.12)!important;
}

.nove.Mui-disabled:hover{
  background-color:rgba(0, 0, 0, 0.12)!important;
}



.uli-cont{
  color:  #004e56;
  list-style-position: inside!important;
  padding: 0!important;
  margin-top: 0!important;
  margin-bottom: 1rem!important;
  
}


.uli-cont span {
  color:rgb(17, 24, 39);
  font-size: 0.8rem !important;

  padding: 0!important;
  margin: 0!important;
  justify-content: space-between!important;
  position: relative!important;
  left: -8px!important;

}

.boton-regis{
  text-align: center!important;
  align-items: center!important;
  justify-content: space-between!important;
  display: block!important;
  margin-top: 0.8rem!important;
}

.boton-tal1{
  width:50%;
  box-sizing: border-box;
  outline: 0px;
    border: 0px;
    margin: 0px;
font-weight: 600!important;
    font-size: 0.8rem;
    
    
    padding: 8px 22px;
    
    
    cursor:pointer;
    background-color: white!important;
    border-color: #e7e7e7!important;
    box-shadow: none;
    color: black !important;
    /* -webkit-appearance: none!important;
    -moz-appearance: none!important;
  appearance: none!important; */
   /*  color: #004e56 !important;
    border-bottom: 2px solid  #004e56!important; */
}

.boton-tal2{
  width:50%;
  box-sizing: border-box;
  outline: 0px;
    border: 0px;
    margin: 0px;
font-weight: 600!important;
    font-size: 0.8rem;

    border-bottom: 2px solid   rgb(255, 255, 255)!important;
    padding: 8px 22px;
   
    
    cursor:pointer;
    background-color: rgb(255, 255, 255)!important;
    box-shadow: none;
    color: rgba(0,0,0,0.6) !important;
    /* -webkit-appearance: none!important;
    -moz-appearance: none!important;
  appearance: none!important;  */
}

.boton-tal2:hover{
  background-color: #edeae5!important;
 border-bottom: 2px solid   #004e56!important;
 color:  #004e56!important;
/*  -webkit-appearance: none!important;
 -moz-appearance: none!important;
 appearance: none!important;  */
}



.boton-tal1:hover{
  background-color: #edeae5!important;
  /* -webkit-appearance: none!important;
   -moz-appearance: none!important;
  appearance: none!important;  */
 
}

.underline2{
  /*  position: absolute;
   bottom: 732px;
   left: 0;
   right: 0; */
   position: relative;
   left: -22px;
   bottom: -8px;
   height:2px;
  /*  width: 192px; */
  width: calc(100% + 44px);
   background: #004e56;
/*    background: #f9b934; */
 }

 .agregueFoot {

  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  /* align-items: center; */
  min-height: 0;
  position: relative;
  min-height: calc(100vh - 60px)!important;
  justify-content: space-between;
 }
 .agregueFoot2 {

  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  /* align-items: center; */
  min-height: 0;
  position: relative;
  min-height: calc(100vh - 80px)!important;
  justify-content: space-between;
 }

 .agregueFoot3 {

  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  /* align-items: center; */
  min-height: 0;
   position: relative; 
  min-height: calc(100vh - 60px)!important;
  justify-content: space-between;
 }

 @media (max-width: 600px) {
 .ajustarPad{
  
  padding-left: 8vw!important;
  padding-right: 8vw!important;
 }
}


.tres2{
  width: 32rem;
   max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
 }

@media (max-width: 600px) {
  .tres2{
   
   margin-left: 0!important;
   margin-right: 0!important;
   width: 100% !important;
   max-width: 32rem;
  }
 }

 .cambioPad{
  margin-left: 0!important;
   margin-right: 0!important;
   padding-left: 8vw!important;
  padding-right: 8vw!important;
   width: 100% !important;
 }

/*  *::-webkit-scrollbar{
  width: '0.4em'
}
*::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00)
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.1);
  outline: 1px solid slategrey
} */

.desabMui.Mui-disabled{
  background-color: #FF9800!important;
}

.desabMui.Mui-disabled:hover{
  background-color: #FF9800!important;
}

.desabMui2.Mui-disabled{
  background-color: transparent!important;
}

.desabMui2.Mui-disabled:hover{
  background-color: transparent!important;
}



.desabMui3.Mui-disabled{
  color: rgba(0, 0, 0, 0.54) !important;
  background-color: transparent!important;
}

.desabMui3.Mui-disabled:hover{
  color: rgba(0, 0, 0, 0.54) !important;
  background-color: transparent!important;
}

.desabMui4.Mui-disabled{
  background-color: #fff!important;
}

.desabMui4.Mui-disabled:hover{
  background-color: #fff!important;
}

.desabMui5.Mui-disabled{
  background-color: #fff!important;
  color:rgba(0, 0, 0, 0.87)!important;
}

.desabMui5.Mui-disabled:hover{
  background-color: #FF9800!important;
  color:rgba(0, 0, 0, 0.87)!important;
}

.desabMui6.Mui-disabled{
  color: gainsboro!important;
 background-color: transparent!important;
}

.desabMui6.Mui-disabled:hover{
 color: gray!important;
 background-color: transparent!important;
 
}

.desabMui7.Mui-disabled{
  background-color: transparent!important;
  color: rgba(0,0,0,0.54) !important;
}

.desabMui7.Mui-disabled:hover{
  background-color: transparent!important;
  cursor: pointer!important;
  color: rgba(0,0,0,0.54) !important;
}

.desabMui8.Mui-disabled{
  background-color: transparent!important;
  color: rgba(0,0,0,0.6) !important;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%), inset 0 0 0 2px rgb(0 0 0 / 0%), inset 0 0 0 1px rgb(0 0 0 / 0%)!important;
}

.desabMui8.Mui-disabled:hover{
  background-color: rgba(207,207,207,0.25)!important;
  cursor: pointer!important;
  color: rgba(0,0,0,0.6) !important;
  box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%) !important; 
}

.sinBack77{
  color:  rgba(0, 0, 0, 0.54)!important;
  background-color: #fff!important;
}

.sinBack78.Mui-disabled{
  color:  red!important;
  background-color: #fff!important;
}

/* input{
  -webkit-appearance: none!important;
  -moz-appearance: none!important;
appearance: none!important;
} */

/*  input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}  */

/* html, body {
  overflow: hidden;
} */


body {
  -webkit-overflow-scrolling: touch!important;
}

html {
  overflow-y: scroll;
}

.botonHero1{
  color: #1a2138!important;
  background: #f9b934!important;
  cursor: pointer;
    border: none!important;
    text-align: center!important;
    /* box-sizing: border-box!important; */
    font-weight: 600!important;
    line-height: 1.5!important;
}

.botonHero1:hover{
  color: #fff;
    background: #FF9800!important;
}

.botonHero2{
  color: #1a2138!important;
  /* background: #f9b934!important; */
  cursor: pointer;
    border: none!important;
    text-align: center!important;
    /* box-sizing: border-box!important; */
    font-weight: 600!important;
    line-height: 1.5!important;
}

.botonHero2:hover{
  /* color: #fff; */
  text-decoration: underline!important;
    background: transparent!important;
}

#nprogress .bar {
  background: #2e7d32 !important;
  z-index: 1201!important;
 
}

#nprogress .peg {
  box-shadow: 0 0 10px #2e7d32, 0 0 5px #2e7d32;
}

#nprogress .spinner-icon {
  border-top-color: #2e7d32;
  border-left-color: #2e7d32;
}

@media (max-width: 950px) and (min-width:600px){

  .typoArribaNoso{
      margin-right: 40px!important;
  }
}


@media screen and (min-width: 600px) {
  .centroAbajoPlanes{
    line-height: 40px!important;
    font-size: 32px!important;
}
}

.progressBarBlog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: #006400;
  transform-origin: 0%;
  
}

.pruebaNos2{
    
  font-size:48px; 
  font-weight:700;
  font-family: 'Segoe UI', Arial, sans-serif;
  display: inline-flex!important;
  margin: 0!important;
  color:#2e7d32!important;

}

@media screen and (min-width: 600px) {
  .textoPlanes2{
    font-size: 20px!important;
}

}

@media (max-width:900px){
  .romperNoso{
      display: block!important;
  }
}

.noHover45{
  background-color: transparent!important;

}

.noHover45:hover{
  background-color: transparent!important;

}

.boton-subircv2Con2{
  color: rgba(0, 0, 0, 0.87)!important;
  background: #f9b934!important;
  cursor: pointer;
  
    text-align: center!important;
    box-sizing: border-box!important;
    font-weight: 500!important;
    border-radius: 9999px!important;
}

.boton-subircv2Con2:hover{
  color: rgba(0, 0, 0, 0.87);
    background: #FF9800!important;
}

.boton-subircv2Con{
  color: rgba(0, 0, 0, 0.87)!important;
  background: #f9b934!important;
  cursor: pointer;
  
    text-align: center!important;
    box-sizing: border-box!important;
    font-weight: 500!important;
    border-radius: 5px!important;
}

.boton-subircv2Con:hover{
  color: rgba(0, 0, 0, 0.87);
    background: #FF9800!important;
}

.boton-subircv2-desabCon{
  color: rgba(0, 0, 0, 0.26)!important;
  background: rgba(0, 0, 0, 0.12)!important;
  cursor: pointer;
  
    text-align: center!important;
    box-sizing: border-box!important;
    font-weight: 500!important;
    border-radius: 5px!important;
    border:0px solid rgba(0, 0, 0, 0.26)!important
}
.boton-subircv2-desabCon:hover{
  color: rgba(0, 0, 0, 0.26);
    background: rgba(0, 0, 0, 0.12)!important;
}

.boton-subircv2{
  color: rgba(0, 0, 0, 0.87)!important;
  background: #f9b934!important;
  cursor: pointer;
  
    text-align: center!important;
    box-sizing: border-box!important;
    font-weight: 500!important;
    border-radius: 0!important;
}



.boton-subircv2:hover{
  color: rgba(0, 0, 0, 0.87);
    background: #FF9800!important;
}

.ContactHelpBot:hover{
  background-color: transparent!important;
}

.linksinDecor{
  text-decoration: none!important;
  color:black!important;
  font-weight: 600!important;
}

@media screen and (min-width: 600px) {
    

  .planesArriba{
      padding-top: 80px!important;
      padding-bottom: 96px!important;
  }
  .textoPricing{
    font-size: 48px!important;
}

}

.svgSearchItems{
  position: absolute!important;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  top: 0!important;
  pointer-events: none!important;
}

.hoverAcor:hover .hovereoAc{
  font-weight: 600!important;
}

.shadoweo {
  box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow)!important;
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)!important;
  --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px 0 var(--tw-shadow-color)!important;
}

.sin-arriba{
  padding-top: 8px!important;
}

.pdt8px{
  padding-top: 8px!important;
}


@media screen and  (min-width: 600px) {

  .flexoHeaderBusca{
      flex-direction: row!important;
      align-items: center!important;
      margin-top: 48px!important;
      margin-bottom: 48px!important;
  }

}

@media screen and  (min-width: 960px) {
  .textoBuscaCand2{
      font-size: 36px!important;
      line-height: 1.375!important;
  }
  
}


.noMensajesMail{
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.imequeMensa{
  height:calc(100px - 16px)!important;
  width: auto !important;
}

.imequeMensa:hover{
  height:calc(100px - 8px)!important;
  width: auto !important;
}

.faqMod1{
  padding-left: 4vw!important;
  padding-right: 4vw!important;
  z-index: 5!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  position: relative!important;
  object-fit: cover!important;
  height: 350px!important;
  background-color: #f5f5f5!important;
}

.rectMod{
  width: 100%!important;
  height: 350px!important;
  position: absolute!important;
  background-color: #f5f5f5!important;
}

.faqMod2{
  position: absolute!important;
  height: 350px!important;
  width: 100%!important;
  object-fit: cover!important;
  background-color: #f5f5f5!important;
}

.faqModEsp{
  width: 100%!important;
  flex-direction: column!important;
  justify-content: center!important;
  min-width: 250px!important;
  line-height: 1.2!important;
  margin-top: 40px!important;
    margin-bottom: 60px!important;
    margin-right: 0px!important;
    margin-left: 0px!important;
}

@media screen and  (min-width: 960.01px) {
  .faqModEsp{
    margin-top: 80px!important;
    margin-bottom: 120px!important;
  }

}

@media screen and  (min-width: 900.01px) {
  .faqModEsp{
    margin-right: 50px!important;
    margin-left: 50px!important;
  }
}

.faqMod3{
  width: 1!important;
  max-height: 120!important;
  bottom: 0!important;
  position: absolute!important;
  z-index: 2!important;
}

.faqMod4{
  font-size: 48px!important;
  font-weight: 700!important;
  font-family: 'Segoe UI', Arial, sans-serif!important;
  display: inline-block!important;
  margin: auto!important;
  justify-content: center!important;
  position: relative!important;
  text-align: center!important;
  line-height: 1.2!important;
}

.faqMod5{
  min-width: 250px!important;
  font-size: 20px!important;
  font-weight: 400!important;
  font-family: 'Segoe UI', Arial, sans-serif!important;
  display: flex!important;
  justify-content: center!important;
  text-align: center!important;
  line-height: 1.5!important;
  z-index: 4!important;
}

.coloreModTrab{
  color:rgb(189,189,189)!important ;
}



@media screen and  (min-width: 600.01px) {
.coloreModTrab{
  color:gray!important;
}
}

.swiper-wrapper{
  /*  margin-left:30px!important; */
  height:100%;
  /* -webkit-transition-timing-function:linear!important; 
 -o-transition-timing-function:linear!important;
 transition-timing-function:linear!important;  */
}

.slider{
  display: block!important;
  width: auto!important;
  height: calc(5rem+4px)!important; 
object-fit: cover!important;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: auto!important;
}

.swiper-button-next{
  color: #004e56 !important;
  right: 0%!important;
top: 40% !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 30px!important;
}

.swiper-button-prev{
  color: #004e56 !important;
  left: 0%!important;
  top: 40% !important;
}


.swiper-pagination-bullet-active{
background-color: #004e56 !important;
}


.swiper-pagination{
position: relative !important;
}

.swiper-slide2 {
text-align: center;
font-size: 18px;
background: #fff;

/* Center slide text vertically */
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;
/* margin-right: 30px; */
}

.swiper-slide2 img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}

.swiper-wrapper{
/*  margin-left:30px!important; */
height:100%;
/* -webkit-transition-timing-function:linear!important; 
-o-transition-timing-function:linear!important;
transition-timing-function:linear!important;  */
}

.swiper-image {
display: block;
width: auto;
height: 5rem;
object-fit: cover;
}