


  .navi {
    height: 80px;
  }

  .navi3 {
    height: 60px;
   
  }

  @media (max-width: 899.9px){
  .navi3 {
    justify-content: flex-end!important;
  }
}






  .item_navbar {
    
    font-weight: 700!important;
   
   
  }

  .item_navbar :hover {
    text-decoration: underline;
    text-decoration-thickness: 0.01em;
    font-weight: 700!important;
   
    /* text-decoration-color: red; */
    text-underline-offset: 35%;
    /* font-weight: 700; */
  }


  .pri-li {
padding: 1rem;
cursor:pointer;
font-size: 14px!important;

  }

  .pri-lo {
    padding: 1rem;
    cursor:pointer;
    font-size: 14px !important;
    
      }


   @media screen and (max-width:1040px) {
    .pri-lo {
      display: none!important;
    }
    

    @media screen and (min-width:900px){
    
    .boton-registro {
      width:100px!important;
      
    }

   
    
  }
  } 

  @media screen and (max-width:899.9px) {
    .pri-li {
      display: none!important;
    }



    
/* .boton-registro {
  width:100px!important;
}

.boton-login {
  width:120px!important;
}
   */
  
  }




 
.boton-login{
  cursor: pointer;
  text-align: center!important;
border-radius: 0!important;
box-sizing: border-box!important;
  color: #241c15!important;

  padding: 0.75rem 1rem!important;

box-shadow: inset 0 0 0 0.0625rem #241c15!important;
  background: rgba(36,28,21,0)!important;
line-height: 1.5!important;
font-weight: 500!important;
}

.boton-login:hover{
  background: rgba(36,28,21,.1)!important;
}


.boton-logino{
  cursor: pointer;
  text-align: center!important;
border-radius: 9999px!important;
/* box-sizing: border-box!important; */
  color: #241c15!important;

  padding: 0.75rem 1rem!important;

box-shadow: inset 0 0 0 0.0625rem #241c15!important;
  background: rgba(36,28,21,0)!important;
line-height: 1.5!important;
font-weight: 500!important;
}

.boton-logino:hover{
  background: rgba(36,28,21,.1)!important;
}


.boton-loginN{
  cursor: pointer;
  text-align: center!important;
/* border-radius: 16px!important; */
/* box-sizing: border-box!important; */
  color: #241c15!important;
/* 
  padding: 0.75rem 1rem!important; */

box-shadow: inset 0 0 0 0.0625rem rgba(36,28,21,0.1)!important;
  background: rgba(36,28,21,0)!important;
line-height: 1.5!important;
font-weight: 500!important;
}

.boton-loginN:hover{
  background: rgba(36,28,21,.1)!important;
}

.boton-registro{
  color: #fff!important;
  background: #007c89!important;
  cursor: pointer;
    border: none!important;
    text-align: center!important;
    box-sizing: border-box!important;
    font-weight: 500!important;
    border-radius: 0!important;
}

.boton-registroN{
  color: #fff!important;
  background: #007c89!important;
  cursor: pointer;
    border: none!important;
    text-align: center!important;
    /* box-sizing: border-box!important; */
    font-weight: 500!important;
    line-height: 1.5!important;
   
}

.boton-registro2{
  color: #fff!important;
  background: #007c89!important;
  cursor: pointer;
    border: none!important;
    text-align: center!important;
    box-sizing: border-box!important;
    font-weight: 500!important;
    border-radius: 0!important;
}

.boton-registro:hover{
  color: #fff;
    background: #004e56!important;
}

.boton-registroN:hover{
  color: #fff;
    background: #004e56!important;
}


.boton-registro2:hover{
  color: #fff;
    background: #004e56!important;
}

.recto{
  color:#1d1d1d!important;
  font-size:0.8rem!important;
  box-shadow: none;
  
}

.recto:hover {

  color: #fff!important;
  background-color:#004e56!important;

 /*   background-color: #f9b934!important;
  color: rgba(0, 0, 0, 0.87)!important;
  */
  /* color: #cfcccc!important; */
}

.botontopbar{
padding-top: 0!important;
padding-left: 0!important;
padding-right: 0!important;
padding-bottom: 0!important;
}

@media (min-width: 960px){

 .botontopbar{
  padding-bottom: 0.1rem!important; 
  padding-top: 0.1rem!important; 
  padding-left: 0.2rem!important; 
  padding-right: 0.2rem!important; 
} 
}

.botontopbar:hover{
  background-color:rgba(0, 0, 0, 0.12) !important
}

@media (min-width: 900px){
.gridtopbar{
  display: flex!important; 
}
}

@media (max-width: 899.9px){
  .gridtopbar2{
    display: flex!important; 
  }
}

 .avatop{
  display:flex!important;
  
} 

@media (min-width: 900px){
  .avatop{
    margin-left: 0.1rem!important; 
    margin-right: 0.1rem!important; 
    margin-top: 0.1rem!important; 
      margin-bottom: 0.1rem!important;
  }
  }
  @media (min-width: 900px){
    .botone2{
     border-radius: 9999px;
    }
    }


  @media (max-width: 900px){
    .avatop2{
      margin-left: 0.2rem!important; 
      margin-right: 0.2rem!important; 
      margin-top: 0.2rem!important; 
      margin-bottom: 0.2rem!important; 
     
    }
    }

.topotop{
  display: flex!important; 
font-weight: 600!important; 
}

.paddingarribayabajo{
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}

@media (max-width: 900px){
.transformation1{
  transform: translate(0px, 4px)!important;
}
}


.coloreMenu:hover{
 /*  background-color: #004e56!important;
  color: white!important;  */

 background-color: #f9b934!important;
  color: rgba(0, 0, 0, 0.87)!important;
 
}

.coloreMenu:hover .vero2{
 /*  background-color: #004e56!important;
  color: white!important; */

  background-color: #f9b934!important;
  color: rgba(0, 0, 0, 0.87)!important;
 
 
}

/* color: rgba(0, 0, 0, 0.87)!important;
background: #f9b934!important;


color: rgba(0, 0, 0, 0.87);
  background: #FF9800!important; */

/* .coloreMenuTexto:hover{
  color: white!important;
 
} */

.pruee2{
 /*  position: relative!important; 
  top:-180px!important;
  right:-280px!important; */ 
  background: #fff !important;
  position: fixed!important; 
  top:15px!important;
  left:310px!important;
  z-index: 99999!important; 
}

.pruee2:hover{
  background: gainsboro !important;
}

.Nopruee22{
  /*  position: relative!important; 
   top:-180px!important;
   right:-280px!important; */ 
   background: #fff !important;
   /* position: fixed!important;  */
   top:10px!important;
   right:20px!important;
   z-index: 99999!important; 
   /* transform: translate3d(0,0,0)!important; */
 }
 
 .Nopruee22:hover{
   background: gainsboro !important;
 }

.hovereo:hover{
  background: #241c150a !important;
}

.shadoweoNoto{
  box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow)!important;
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)!important;
  --tw-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px 0 var(--tw-shadow-color)!important;
}

.snakeNoti{
  margin-left: auto!important;
  margin-right: auto!important;
  max-width: 320px!important;
  width: 100% !important;
  position: relative!important;
  pointer-events: auto!important;
  padding-top: 4px!important;
}


  



.pri-li:hover .hoverArribaTopNoUs{
  text-decoration: underline!important;
  text-decoration-thickness: 1px!important;
  text-underline-offset: 4px!important;
}