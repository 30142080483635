
.navi1{
    padding: 0 4%;
    height: 80px;
}

.navi2{
    padding: 0 4%;
    height: 60px;
}


.cont-nav {
   margin: 0!important;
   padding: 0!important;
   width:100%;
   align-items: center;
   height: 80px;
}

.cont-nav2 {
    margin: 0!important;
    padding: 0!important;
    width:100%;
    align-items: center;
    height: 60px;
/*     max-width:80rem!important;
    margin-left:auto!important;
    margin-right:auto!important; */
 }

@media screen and (min-width: 800){
    .cont-nav{
  
       /*  max-width: 100; */
    }
}

.listoca {
    background: 0 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

@media screen and (min-width: 800){
    .verso{
        display:none!important;
    }
    
}

.c-nav--primary .c-nav__list .c-nav-level--1 {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    flex: 1;
}

ul li div a {
    width: 100%;
    height: 100%;
    color: inherit;
    font-weight: 700;
    
  }
  
  ul li div a :hover {
    text-decoration: underline;
    text-decoration-color: #241c15;
    text-underline-offset: 35%;
    
  }

  .cual-es{
    max-width: 400px!important;
  }

  .botonTopArriba{
    text-decoration: none!important;
    color:rgb(96, 108, 120);
    font-weight: 600!important;
  }

.botonTopArribaeste{
    border-radius: 9999px!important;
  
    margin-right: 8px!important;
    margin-left: 8px!important;
    
}



  .botonTopArribaeste:hover{
    background-color:rgba(0, 0, 0, 0.04) !important;
    /* font-weight: 600!important; */
  }


  .botonTopArribaeste2{
    border-radius: 9999px!important;
  
    margin-right: 8px!important;
    margin-left: 8px!important;
    
}

@media screen and (max-width: 1000px){
.botonTopArribaeste2{
  margin-right: 0px!important;
  margin-left: 0px!important;
}
}

  .botonTopArribaeste2:hover{
    background-color:#fff !important;
    
    /* font-weight: 600!important; */
  }

  .botonTopArriba:hover{
    color: #000 !important;
  }

  .activoLink{
    color: #000 !important;
    text-decoration: none!important;
    font-weight: 600!important;
  }