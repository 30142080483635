

.colapsable {
}
.fuchi1{
  background-color: #edeae5;
 /*  background-image: url("https://www.transparenttextures.com/patterns/blizzard.png");
   */
   z-index: 2;
   cursor: pointer;
}


.medio-uno::before {
  content : "";
    background-color: #bababa;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    border-radius: 50px;
    height: 2px;

}
.rotacion {
  transform: rotate(90deg);
  transition: transform 100ms ease;
}

li a {
  width: 100%;
  height: 100%;
  color: inherit;

  text-decoration: none;
}

li a :hover {
  text-decoration: underline;
  text-decoration-color: #241c15;
}

.dentro {
  display: none;
}

.elim {
  display: inline-block;
}

@media (min-width: 750px) {
  .elim {
    display: flex !important;
    width: 400px;
  }
}

.imagew2 {
  height: 120px;

  padding-bottom: 1rem;
}

.fuchi1 {
  border: none;
  width: 100%;
  padding: 0;
  display: block;
}

.casi-todo-fuch {
  padding-top: 2rem;
  padding-bottom: 0;

}

.casi-todo-fuch2 {
  padding-top: 0rem;
  padding-bottom: 0;

}

.abajo-foot {
  text-align: left;
  margin-bottom: 0;
  clear: both;
  margin-top: 0;
  background-color: transparent;
}

.copyright-foot {
  padding: 0 4vw;
  height: auto;
  text-align: left;
  font-weight: 400;
  line-height: 1.2857;
  letter-spacing: normal;
  color: #454245;
padding-bottom: 2rem;
padding-top: 1rem;
  margin-top: 0;
  
  background-color: transparent;
}

.ultimo-div {
  height: auto;
  text-align: left;
  font-weight: 400;
  line-height: 1.2857;
  letter-spacing: normal;
  color: #454245;
  
}

.primer-div-foot {
  margin: 0 auto;
  padding: 0 4vw;
}

.link-foot {
  border: none;
  color: #1264a3;
  cursor: pointer;
  text-decoration: none;
  /* word-break: break-word;
 */
  background: 0 0;
}

.nav-footer {
  display: block;
  margin-bottom: 1rem;
 /*  margin-top: 2rem; */
}

.nuevo-uno {
  display: grid;
  margin-top: 0;
  padding: 0;
  font-weight: 400;
  letter-spacing: normal;

  margin-bottom: 1rem;
}

.oculto {
  display: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.oculto2 {
  display: none;
}

.oculto3 {
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .nuevo-uno {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
  .link-prueba {
    display: none !important;
    justify-content: center;
  }

  .oculto {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .link-prueba {
    display: none !important;
    justify-content: center;
  }

  .oculto {
    display: flex;
  }
  .oculto2 {
    display: contents;
  }

  .oculto3 {
    display: none !important;
  }

  .nuevo-dos {
    display: block !important;
    justify-content: space-between;
  }

  .nuevo-tres {
    display: block;
    text-align-last: justify !important;
font-size: 1rem;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .nuevo-uno {
    display: block;
  }

  .nuevo-cinco {
    height: 40px;
    line-height: 40px;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .nuevo-uno {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  .link-prueba {
    display: none !important;
    justify-content: center;
  }

  .oculto {
    display: flex;
  }
}

.nuevo-dos {
  padding: 0;
  display: inline-block;

  font-weight: 700;
}

.nuevo-tres {
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  font-size: 0.875rem;
  height: 40px;
  position: relative;
}

.nuevo-cuatro {
  list-style-type: none;
  padding: 0;

  margin: 0;
  flex-basis: 0;
  flex-direction: column;
  transition: flex 0.5s ease-in-out;
}

.nuevo-cinco {
  font-size: 0.875rem;
  height: 40px;
  position: relative;
  border: none;
  font-weight: 400;

  cursor: pointer;
  text-decoration: none;
  /* word-break: break-word; */
}

.nuevo-cinco:hover {
  text-decoration: underline !important;
  text-decoration-color: #241c15;
}

@media screen and (min-width: 1024px) {
  .nav-footer {
    margin-top: 0;
  }

  .primer-div-foot {
    /*  display: flex; */
    justify-content: space-between;
    align-items: flex-start;
  }

  .nuevo-uno {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
  }
}

@media screen and (min-width: 600px) {
  .abajo-foot {
    text-align: left;
    margin-bottom: 0;
    padding: 0 4vw;
  }
  .copyright-foot {
    font-size: 0.75rem;
  }
  .ultimo-div {
   
    margin: 0 auto;
    font-size: 0.75rem;
  }

  .primer-div-foot {
  }

  .nuevo-tres {
    display: inline-flex;
    align-items: flex-start;
  }

  .nuevo-cuatro {
    flex-direction: column;

    display: flex;
  }

  .nuevo-cinco {
    display: inline-flex;
    align-items: flex-start;
  }
}

.link-prueba {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .social-foot {
    height: 20px;
    width: 20px;
  }
}

.medio-uno {
  position: relative;
  margin: 0 auto;
}

.medio-uno2 {
  position: relative;
  margin: 0 auto;
}

.medio-dos {
  display: inline-block;
  width: 100%;
  
  margin-bottom: 0;
  margin: 0;

  list-style: none;

  font-weight: 400;
  line-height: 1.4444;
  letter-spacing: normal;
}

.medio-tres {
  border: none;
  margin-left: auto;
  color: #1264a3;
  font-weight: 700;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  padding: 1rem 0;
  background: 0 0;
}

.medio-cuatro {
  margin-right: 1rem;
  display: inline-block;
  padding: 1rem 0;
  list-style: none;
  font-weight: 400;
  line-height: 1.4444;
  letter-spacing: normal;
}

.medio-cinco {
  font-weight: 700;
  line-height: 1.2857;
  letter-spacing: normal;
font-size: 0.875rem;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;

  /* word-break: break-word; */
  background: 0 0;
}
.medio-seis {
  display: inline-block;
  padding: 1rem 0;
  font-weight: 400;
  line-height: 1.4444;
  letter-spacing: normal;
}

.medio-siete {
  padding-left: 0;
  color: #454245;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  /*     word-break: break-word; */
  background: 0 0;
}
/* 
@media screen and (min-width: 84.875rem)
.o-content-container {
    max-width: 76.875rem;
    padding: 0;
}
 */

@media screen and (min-width: 600px) {
  .medio-uno {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .medio-uno2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .medio-dos {
    width: auto;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .medio-tres {
    text-align: left;
  }

  .medio-cuatro {
    
    margin-bottom: 0;
    
  }

  .medio-cinco {
    font-size: 0.875rem;
  }
  .medio-seis {
    margin-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .social-foot {
    height: 20px;
    width: 20px;
    margin-top: 5px;
  }
}

@media screen and (min-width: 600px) and (max-width: 825px) {
 
 .medio-uno {
    display: block;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .medio-uno2 {
    display: block;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
 
  .medio-seis {
    margin-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .medio-cuatro {
    white-space:nowrap;
    padding-bottom: 1rem;
    text-align: center;
    }

  .abajo-foot{
    text-align: left;
    margin-bottom: 0;
    padding: 0 4vw;
  }

  .supa1{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .supa{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .medio-siete{
    margin-right: 1.5rem;
  }

  .social-foot {
 
    margin-top: 5px;
  }

}




@media screen and (max-width: 599.9px) {
  .medio-uno {
    display: block;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .medio-uno2 {
    display: block;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .medio-dos {
    display: flex;
    margin-left: 0;
    padding-left: 0;
  }
 
  .medio-seis {
    margin-left: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .medio-cuatro {
    
    padding-top: 1rem;
    padding-bottom: 1rem;
   /* width:100px!important; */
    }

    .medio-cinco {
       display: block;
    }

  .abajo-foot{
    text-align: left;
    margin-bottom: 0;
    padding: 0 4vw;
  }

  .supa1{
    display: grid;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
  }

  .supa{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .medio-siete{
    margin-right: 1.5rem;
  }

 
}

.abajo-foot2{
  text-align: left;
    margin-bottom: 0;
    padding: 0 4vw;
  clear: both;
  margin-top: 0;
 /*  background-color: #fff; */
}

.colorfoot{
  /* background-color: #fff; */
}

.separacion{
  padding-top: 24px;
}

.overfleo{
  overflow:hidden;
}

.pruebaLinkUl{
  display: flex!important;
}